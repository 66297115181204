import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../components/common/EventBus";
import EventBusHandler from "../components/common/EventBusHandler";

const API_URL = "/api/";

const getActiveInterchange = (callback) => {
    console.log('getActiveInterchange', authHeader())
    return axios.get(`${API_URL}ActiveInterchange`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getActiveInterchange logout");
                EventBusHandler.dispatch("logout");
            }
        }
    );
};

const getEqPhotos = (equipNum, callback) => {
    console.log('getEqPhotos', authHeader())
    var address = equipNum ? `${API_URL}EquipmentPhotos/${equipNum}` : `${API_URL}AllEquipmentPhotos`;
    return axios.get(address, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getEqPhotos logout");
                EventBus.dispatch("logout");
            }
        }
    );
};

const getApprovedInterchange = (interchangeDate, callback) => {
    console.log('getApprovedInterchange', authHeader())
    return axios.get(`${API_URL}ApprovedInterchange/${interchangeDate}`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getApprovedInterchange logout");
                EventBus.dispatch("logout");
            }
        }
    );
};
const getEquipmentIn = (callback) => {
    console.log('getEquipmentIn', authHeader())
    return axios.get(`${API_URL}EquipmentIn`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getEquipmentIn logout");
                EventBus.dispatch("logout");
            }
        }
    );
};


const getEquipment = (containerNumber, ioutNumber, callback) => {
    console.log('getEquipment', authHeader())
    return axios.get(`${API_URL}Equipment/${containerNumber}/${ioutNumber}`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getEquipment logout", error);
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 404) {
                console.log("444444000000004444444 ", error.response.status);
                callback(null)
            }
        }
    );
};


const getEquipmentQuery = (equipNumber, callback) => {
    console.log('getEquipmentQuery', authHeader())
    return axios.get(`${API_URL}EquipmentQuery/${equipNumber}`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getEquipmentQuery logout", error);
                EventBus.dispatch("logout");
            }
            else {
                callback(null)
            }
        }
    );
};

const saveEquipmentPicture = (equipNumber, photo, callback) => {
    console.log('saveEquipmentPicture', authHeader())
    console.log(photo);
    return axios.put(`${API_URL}EquipmentQuery/${equipNumber}/SavePicture`, photo, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE saveEquipmentPicture logout", error);
                EventBus.dispatch("logout");
            }
            else {
                callback(null)
            }
        }
    );
};

const saveEquipmentPhotoInfo = (equipNumber, info, callback) => {
    console.log('saveEquipmentPhotoInfo', authHeader())
    console.log(info);
    return axios.put(`${API_URL}EquipmentQuery/${equipNumber}/SaveInfo`, info, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE saveEquipmentPhotoInfo logout", error);
                EventBus.dispatch("logout");
            }
            else {
                callback(null)
            }
        }
    );
};

const getShippingLines = (callback) => {
    console.log('getShippingLines', authHeader())
    return axios.get(`${API_URL}ShippingLines`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getShippingLines logout");
                EventBus.dispatch("logout");
            }
        }
    );
};

const dechargeEquipment = (num, cntr_num, param, callback, callbackerror) => {
    console.log('dechargeEquipment', num, authHeader())

    return axios.put(`${API_URL}EquipmentIn/${num}/${cntr_num}`, param, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE dechargeEquipment logout", error.response);
                EventBus.dispatch("logout");
            }
            else if (callbackerror)
                callbackerror();

        }
    );
};

const updateInterchangePhoto = (num, photo, callback, callbackerror) => {
    console.log('updateInterchangePhoto', num, authHeader())
    console.log(photo)
    return axios.put(`${API_URL}interchange/save/photo/all/${num}`, photo, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE updateInterchangePhoto logout", error.response);
                EventBus.dispatch("logout");
            }
            else if (callbackerror)
                callbackerror();

        }
    );
};

const updateInterchangeSignature = (num, signature, callback, callbackerror) => {
    console.log('updateInterchangeSignature', num, authHeader())

    return axios.put(`${API_URL}interchange/save/signature/${num}`, { "chaine": signature }, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE updateInterchangeSignature logout", error.response);
                EventBus.dispatch("logout");
            }
            else if (callbackerror)
                callbackerror();

        }
    );
};



const updateInterchange = (ioutNumber, interchangeUpdate, callback, callbackerror) => {
    console.log('updateInterchange', ioutNumber, interchangeUpdate, authHeader())

    return axios.put(`${API_URL}interchange/${ioutNumber}`, interchangeUpdate, { headers: authHeader() }).then(
        (response) => {
            console.log(response)
            if (response && response.data && response.data.hasError == true) {
                if (callbackerror)
                    callbackerror();
                if (response.data.errorMessage != "")
                    window.alert("Error...! " + response.data.errorMessage + ".");
            }
            else
                callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE updateInterchange logout", error.response);
                EventBus.dispatch("logout");
            }
            else if (callbackerror)
                callbackerror();

            var message = "Please call technical support.";
            if (error.response.data.length > 0) {
                message = error.response.data;
            }

            window.alert("Error...! Please call technical support.");
        }
    );
};

const isGensetAttachable = (gensetNumber, interchangeNumber, bookingNumber, callback) => {
    console.log('isGensetAttachable', authHeader())
    return axios.get(`${API_URL}genset/${gensetNumber}/isattachable/${interchangeNumber}/${bookingNumber}`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE isGensetAttachable logout", error);
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 404) {
                console.log("444444000000004444444 ", error.response.status);
                callback(null)
            }
        }
    );
};


export default {
    getActiveInterchange,
    getEquipment,
    updateInterchange,
    getEquipmentIn,
    dechargeEquipment,
    getEqPhotos,
    getEquipmentQuery,
    saveEquipmentPicture,
    saveEquipmentPhotoInfo,
    getShippingLines,
    updateInterchangeSignature,
    updateInterchangePhoto,
    isGensetAttachable,
    getApprovedInterchange: getApprovedInterchange
};
