import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Hello!</h1>
        <p>Welcome to your Hunt intranet services!</p>
      </div>
    );
  }
}
