// inspired by https://leanpub.com/redux-book
import axios from "axios";
import { API } from "../types/apiTypes";
import { accessDenied, apiError, apiStart, apiEnd, accessLogin } from "../actions/apiAction";
import EventBus from "../components/common/EventBus";


const API_URL = "/api/";


const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);

    console.log(action.type)
    if (action.type !== API) return;

    console.log(`${API_URL}${action.payload.url}`);
    const {
        url = `${API_URL}${url}`,
        method,
        data,
        onSuccess,
        onFailure,
        preRequest,
        label,
        headers
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";

    if (label) {
        dispatch(apiStart(label));
    }
    console.log({
        url:`${API_URL}${url}`,
        method,
        headers
    });
    if (preRequest)
        preRequest();

    axios
        .request({
            url: `${API_URL}${url}`,
            method,
            headers,
            [dataOrParams]: data
        })
        .then(({ data }) => {
            dispatch(onSuccess(data));
        })
        .catch(error => {
            dispatch(apiError(error, label ? label : ''));
            dispatch(onFailure(error));
            console.log(error);
            if (error.response && (error.response.status === 403)) {
                dispatch(accessDenied(window.location.pathname));
            }
            if ((error.response && error.response.status === 401) || (typeof error === 'string' && error.includes('401'))) {
                EventBus.dispatch("logout");
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label));
            }
        });
};

export default apiMiddleware;