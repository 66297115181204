import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Services from "../services/services";
import { Form as FormAntd, Input, Button, Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import useCollapse from 'react-collapsed';
import 'font-awesome/css/font-awesome.min.css';
import { IdleTimerProvider } from 'react-idle-timer';
import { ReloadOutlined } from '@ant-design/icons';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

import "./Lift.css";

const initialState = {
    terminals: [],
    interchanges: [],
    filteredInterchanges: [],
    currentUserTerminal: null,
    loading: true,
};

const filterInitialState = {
    terminal: "ALL",
    status: 2,
    intDate: moment(Date.now()).format("YYYY-MM-DD"),
    equipment: "BOTH",
    search: "",
    lastUpdatedPropName: "",
}


export const Control = () => {
    const [state, setState] = useState(initialState);
    const [refresh, setRefresh] = useState(Date.now());
    const [filterState, setFilterState] = useState(filterInitialState);
    const [firstLoad, setFirstLoad] = useState(true);

    const currentFilter = useRef(null);
    currentFilter.current = filterState;

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const onIdleTimerProviderPrompt = () => {
    }

    const onIdleTimerProviderActive = (event) => {
    }

    const onIdleTimerProviderAction = (event) => {
    }

    const onIdleTimerProviderIdle = () => {
        //console.log('idle for one minute...');
        //window.location.reload();
        setRefresh(Date.now());
    }
    const renderActiveInterchangeTable = () => {
        return (
            <>
                <IdleTimerProvider
                    timeout={1000 * 60}
                    onPrompt={onIdleTimerProviderPrompt}
                    onIdle={onIdleTimerProviderIdle}
                    onActive={onIdleTimerProviderActive}
                    onAction={onIdleTimerProviderAction} />

                <div className="collapsible">
                    <div className="header" {...getToggleProps()}>
                        <div className="border border-5 border-secondary  overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <table className="" aria-labelledby="tabelLabel">
                                <thead className="bg-secondary">
                                    <tr>
                                        <th className="fs-4" colSpan="3" id="tabelLabel" style={{ color: "white" }}><i className={isExpanded ? 'fa fa-minus' : 'fa fa-plus'}></i> Active Interchange Out</th>
                                        <th className="text-end" style={{ color: "white" }}>Date&nbsp;</th>
                                        <th colSpan="3">
                                            <Space onClick={i => {
                                                i.preventDefault();
                                                i.stopPropagation();
                                            }}>
                                                <DatePicker selected={filterState.intDate} allowClear={false} defaultValue={moment(Date.now())} format={"YYYY-MM-DD"}
                                                    onClick={i => {
                                                        i.preventDefault();
                                                        i.stopPropagation();
                                                    }}
                                                    onChange={(date) => {
                                                        if (date.format("YYYY-MM-DD") !== filterState.intDate) {
                                                            setState({ ...state, loading: true }, setFilterState({ ...filterState, intDate: date.format("YYYY-MM-DD") }));
                                                        }
                                                    }}
                                                />
                                            </Space>
                                        </th>
                                        <th className="text-end" style={{ color: "white" }}>Terminal&nbsp;</th>
                                        <th colSpan="3">
                                            <Form.Select value={filterState.terminal} inline="true" className="fs-7 terminal-select" size="sm" onChange={i => handleFilters(i, "terminal")}>
                                                <option value="ALL">All Terminals</option>
                                                {state.terminals.map((terminal) => (<option value={terminal}>{terminal}</option>))}
                                            </Form.Select>
                                        </th>
                                        <th colSpan="3">
                                            <Form.Control
                                                className="fs-7"
                                                size="sm"
                                                type="text"
                                                name="search"
                                                value={filterState.search}
                                                onClick={i => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }}
                                                onChange={i => {
                                                    handleFilters(i, "search");
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                }} />
                                        </th>
                                        <th>
                                            <Button
                                                style={{ display: 'inline-flex', alignItems: 'center' }}
                                                type="primary"
                                                className="rounded"
                                                icon={<ReloadOutlined />}
                                                onClick={(i) => {
                                                    i.preventDefault();
                                                    i.stopPropagation();
                                                    setRefresh(Date.now());
                                                }}>
                                                Refresh
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>


                    <div {...getCollapseProps()}>
                        <div className="content">
                            {
                                !state.loading &&
                                <div style={{ paddingTop: '0px' }}>
                                    <div className="border border-5 border-secondary  overflow bg-secondary">
                                        <table className="" aria-labelledby="tabelLabel">
                                            {/*<thead className="bg-secondary">*/}
                                            {/*    <tr>*/}
                                            {/*        <th className="fs-4" colSpan="3" id="tabelLabel">Active Interchange Out</th>*/}
                                            {/*        <th className="text-end">Status&nbsp;</th>*/}
                                            {/*        <th colSpan="3">*/}
                                            {/*            <Form.Select value={filterState.status} className="fs-7 status-select fw-bold" size="sm" onChange={i => handleFilters(i, "status")}>*/}
                                            {/*                <option value="0">ACTIVE</option>*/}
                                            {/*                <option value="1">Waiting approval</option>*/}
                                            {/*            </Form.Select>*/}
                                            {/*        </th>*/}
                                            {/*        <th colSpan="4"><Form.Control className="fs-7" size="sm" type="text" name="search" value={filterState.search} onChange={i => handleFilters(i, "search")} /></th>*/}
                                            {/*    </tr>*/}
                                            {/*</thead>*/}
                                            <tbody>
                                                {state.filteredInterchanges.map((interchange) => (
                                                    <div className="row" style={{ width: "140%" }}>
                                                        <tr style={{ width: '100%' }} className="border border-5 border-secondary bg-white" key={interchange.pickupDateTime}>

                                                            <td style={{ width: '120px' }} className="fs-7 cell-pad text-nowrap">
                                                                <span>{moment(interchange.logDateTime).format("YYYY-MM-DD HH:mm")}<br></br></span>
                                                                <span className={`fw-bold ${interchange.interchangeNumber.isRed ? "RED" : ""}`}>{interchange.interchangeNumber.value + " " + interchange.terminal}</span>
                                                            </td>
                                                            <td style={{ backgroundColor: interchange.color, width: '100px' }} className={`fs-7 text-center cell-pad`}>{interchange.client}</td>
                                                            {interchange.status === 0 ?
                                                                (
                                                                    //<td className={`fs-6 fw-bold text-center cell-pad ${interchange.equipment.isGreen ? "bg-green" : ""}`}><span>{interchange.equipment.value}</span></td>
                                                                    <td style={{ width: '150px' }} className={`fs-7  text-center cell-pad`}>
                                                                        <span>{interchange.equipment.value}</span><br />
                                                                        <span>{interchange.bLineIsoCode}</span>
                                                                    </td>
                                                                ) :
                                                                (
                                                                    //  <td className={`text-center cell-pad ${interchange.equipment.isGreen ? "bg-green" : ""}`}>
                                                                    <td style={{ width: '150px' }} className={`text-center cell-pad`}>
                                                                        <span className="fs-7">{interchange.equipment.value}<br></br></span>

                                                                        {
                                                                            interchange.maintIsoCode != "" && interchange.maintIsoCode != null &&
                                                                            <><span>{interchange.maintIsoCode}</span><br /></>
                                                                        }

                                                                        {interchange.equipment.attNum === "" ?
                                                                            (<span className="fs-7">{interchange.equipment.equipNum}</span>) :
                                                                            (<div><span className="fs-7 ">{interchange.equipment.equipNum + " / "}{interchange.equipment.attNum}</span></div>)
                                                                        }


                                                                    </td>
                                                                )}
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.comment.isGreen ? "bg-green" : ""}`}>{interchange.comment.value}</td>*/}
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.repo.isGreen ? "bg-green" : ""}`}>{interchange.repo.value}</td>*/}
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.temperature.isGreen ? "bg-green" : ""}`}>{interchange.temperature.value}</td>*/}
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad ${interchange.ventSet.isGreen ? "bg-green" : ""}`}>{"Vent " + interchange.ventSet.value}</td>*/}
                                                            <td style={{ width: '140px' }} className={`fs-7  text-center cell-pad`}> <span>{interchange.temperature.value}</span><br /><span>{"Vent " + interchange.ventSet.value}</span><br /><span>{((interchange.bookHumidity != null && interchange.bookHumidity != "") ? "H" + interchange.bookHumidity : "") + ((interchange.bookAirExchange != null && interchange.bookAirExchange != "") ? " X" + interchange.bookAirExchange : "")}</span></td>
                                                            <td style={{ width: '140px' }} className="fs-7  text-center cell-pad">{interchange.shippingCompany}</td>
                                                            <td style={{ width: '150px' }} className="fs-7  text-center cell-pad truck-width">
                                                                <span>#{interchange.truckNumber}<br></br></span>
                                                                <span>{interchange.truckLicense}</span>
                                                            </td>
                                                            <td style={{ width: '150px' }} className="fs-7 text-center cell-pad booking-width">
                                                                <span>Booking<br></br></span>
                                                                <span>{interchange.bookingNumber}</span>
                                                            </td>

                                                            <td style={{ width: '250px' }} className={`fs-7 text-center cell-pad`}>{interchange.bookingComments}</td>
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad`}>{interchange.repo.value}</td>*/}
                                                            {/*<td className={`fs-6 fw-bold text-center cell-pad`}>{interchange.temperature.value}</td>*/}
                                                        </tr>
                                                        {interchange.photo1 !== "" && interchange.photo2 !== "" && interchange.photo3 !== "" &&
                                                            <div>
                                                                <div style={{ border: 'solid', marginLeft: '100px', paddingRight: '20px', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px', display: 'inline-block' }}>
                                                                    <img id="imgPhoto1" src={interchange.photo1} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                    <span>{interchange.libelle1}</span>
                                                                </div>
                                                                <div style={{ border: 'solid', paddingRight: '20px', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px', display: 'inline-block' }}>
                                                                    <img id="imgPhoto2" src={interchange.photo2} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                    <span>{interchange.libelle2}</span>
                                                                </div>
                                                                <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px', display: 'inline-block' }}>
                                                                    <img id="imgPhoto3" src={interchange.photo3} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                    <span>{interchange.libelle3}</span>
                                                                </div>
                                                                {
                                                                    (interchange.photo4 !== "" || interchange.photo5 !== "" || interchange.photo6 !== "") &&
                                                                    <div style={{ display: 'flex', marginLeft: '100px', marginBottom: '30px', marginTop: '30px', justifyContent: 'left' }}>
                                                                        {interchange.photo4 !== "" &&
                                                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px' }}>
                                                                                <img id="imgPhoto4" src={interchange.photo4} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                                <span>{interchange.libelle4}</span>
                                                                            </div>
                                                                        }
                                                                        {interchange.photo5 !== "" &&
                                                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px' }}>
                                                                                <img id="imgPhoto5" src={interchange.photo5} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                                <span>{interchange.libelle5}</span>
                                                                            </div>
                                                                        }
                                                                        {interchange.photo6 !== "" &&
                                                                            <div style={{ border: 'solid', borderWidth: '1px', borderColor: 'gray', height: '225px', width: '295px', inset: '10px' }}>
                                                                                <img id="imgPhoto6" src={interchange.photo6} alt="captured-img" style={{ width: '100%', height: '100%' }} />
                                                                                <span>{interchange.libelle6}</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </tbody>
                                            <tfoot className="bg-secondary">
                                                <div className="row" style={{ width: "100%" }}>
                                                    <tr style={{ width: '100%' }} className="border border-5 border-secondary">
                                                        <td style={{ width: '30%' }} className="fs-6 fw-bold cell-pad" colSpan="3">
                                                            {/*<div className="row">*/}
                                                            {/*    <Form.Label className="col-sm-4">Status</Form.Label>*/}
                                                            {/*    <div className="col-sm-8" style={{ paddingLeft: '10px' }}>*/}
                                                            {/*        <Form.Select*/}
                                                            {/*            value={filterState.status}*/}
                                                            {/*            className="fs-7 status-select fw-bold"*/}
                                                            {/*            size="sm"*/}
                                                            {/*            onClick={i => {*/}
                                                            {/*                i.preventDefault();*/}
                                                            {/*                i.stopPropagation();*/}
                                                            {/*            }}*/}
                                                            {/*            onChange={i => {*/}
                                                            {/*                handleFilters(i, "status");*/}
                                                            {/*            }}>*/}
                                                            {/*            <option value="2">APPROVED</option>*/}
                                                            {/*        </Form.Select>*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                        </td>
                                                        <td style={{ width: '40%' }} colSpan="4"></td>
                                                        <td colSpan="4">
                                                            <div className="text-end" onChange={i => handleFilters(i, "equipment")}>
                                                                <Form.Check style={{ display: 'inline-block' }} name="equipment" value="CONTAINERS" type="radio" inline="true" label="Containers / Reefers" className="fs-7 fw-bold" />
                                                                <Form.Check style={{ display: 'inline-block' }} name="equipment" value="GENSETS" type="radio" inline="true" label="Gensets" className="fs-7 fw-bold" />
                                                                <Form.Check style={{ display: 'inline-block' }} name="equipment" value="BOTH" type="radio" inline="true" label="Both" className="fs-7 fw-bold" defaultChecked={filterState.equipment === "BOTH"} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </div>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            }
                            {
                                state.loading == true &&
                                <div className="border border-5 border-secondary  overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                                </div>

                            }

                        </div>
                    </div>
                </div>
            </>
        );
    };

    const updateFilterState = (filter, value) => {
        if (!filter && !value) {
            setFilterState({
                ...filterState,
                lastUpdatedPropName: ""
            });
        } else {
            setFilterState({
                ...filterState,
                [filter]: value,
                lastUpdatedPropName: filter
            });
        }
    };

    const handleFilters = (e, filter) => {
        updateFilterState(filter, e.target.value);
    };

    const render = () => {
        let contents = renderActiveInterchangeTable();
        return <div>{contents}</div>;
    };

    const filterData = (item, filter) => {
        if (item.bookingNumber && item.bookingNumber.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.client && item.client.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.color && item.color.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.comment && item.comment.value && item.comment.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.equipment && item.equipment.value && item.equipment.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.interchangeNumber && item.interchangeNumber.value && item.interchangeNumber.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.logDateTime && item.logDateTime.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.logDateTime && moment(item.logDateTime).format("YYYY-MM-DD HH:mm").toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.repo && item.repo.value && item.repo.value.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.shippingCompany && item.shippingCompany.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.status && item.status.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.temperature && item.temperature.value && item.temperature.value.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.terminal && item.terminal.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.truckLicense && item.truckLicense.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.truckNumber && item.truckNumber.toUpperCase().includes(filter.toUpperCase()))
            return true;
        if (item.ventSet && item.ventSet.value && item.ventSet.value.toString().toUpperCase().includes(filter.toUpperCase()))
            return true;

        return false;
    };

    async function populateActiveInterchangeData() {
        Services.getApprovedInterchange(filterState.intDate, (response) => {
            setState({
                interchanges: response.data.interchange.sort(function (a, b) {
                    return new Date(b.logDateTime) - new Date(a.logDateTime);
                }),
                filteredInterchanges: response.data.interchange.filter(i => i.status === parseInt(filterState.status)),
                terminals: response.data.terminals,
                currentUserTerminal: response.data.currentUserTerminal,
                loading: false,
            });
        });
    }

    useEffect(() => {
        if (state.interchanges.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                if (state.currentUserTerminal !== null && state.currentUserTerminal !== "") {
                    updateFilterState("terminal", state.currentUserTerminal);
                }
            } else {
                updateFilterState();
            }
        }
    }, [state.interchanges]);

    useEffect(() => {
        populateActiveInterchangeData();
    }, [filterState.intDate]);

    useEffect(() => {
        if (filterState.lastUpdatedPropName === "status") {
            populateActiveInterchangeData();
        } else {
            let filtered = state.interchanges.filter((i) => filterData(i, filterState.search));
            filtered = filtered.filter(i => i.status === parseInt(filterState.status));
            if (filterState.terminal !== "ALL")
                filtered = filtered.filter(i => i.terminal === filterState.terminal);
            if (filterState.equipment !== "BOTH") {
                if (filterState.equipment === "GENSETS")
                    filtered = filtered.filter(i => i.equipment.value.indexOf("GENSET") > -1);
                else
                    filtered = filtered.filter(i => i.equipment.value.indexOf("GENSET") == -1);
            }
            if (state.interchanges[0]) {
                setState({
                    ...state,
                    filteredInterchanges: filtered,
                });
            }
        }
    }, [filterState]);

    useEffect(() => {
        populateActiveInterchangeData();
    }, [refresh]);

    return render();
};