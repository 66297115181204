import EventBusHandler from "./EventBusHandler";


const eventBus = {
    on(event, callback) {
        EventBusHandler.on(event, callback);

  },
    dispatch(event, data) {
        EventBusHandler.emit(event, data);
  },
  remove(event, callback) {
      EventBusHandler.off(event, callback);
  },
};

export default eventBus;
