import authHeader from "./auth-header";
import * as types from "../types/apiTypes";
import store from '../redux/store';


const apiAction = ({
    url = "",
    method = "GET",
    data = null,
    preRequest = () => { },
    onSuccess = () => { },
    onFailure = () => { },
    label = ""
}) => {
    const headers = authHeader();
    store.dispatch({
        type: types.API,
        payload: {
            url,
            method,
            data,
            headers,
            preRequest,
            onSuccess,
            onFailure,
            label
        }
    });
}
export default {
    apiAction
};