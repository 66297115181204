import React from "react";
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import EventBus from "./common/EventBus";
import AuthService from "../services/auth.service";
import 'antd/dist/antd.css';


const Login = (props) => {

    const onFinish = (values) => {

        AuthService.login(values.UserCode, values.Password).then(
            (data) => {
                EventBus.dispatch("login", data);
                props.history.push("/index");
            },
            (error) => {
                console.log(error.toString());
            }
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (

        <Row style={{ paddingTop: '10em' }}>
            <Col span={8}></Col>
            <Col span={8}><Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="UserCode"
                    name="UserCode"
                    rules={[{ required: true, message: 'Please input your UserCode!' }]}
                >
                    <Input style={{ borderRadius: '25px' }} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="Password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password style={{ borderRadius: '25px' }} />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" shape="round">
                        Submit
                    </Button>
                </Form.Item>
            </Form></Col>
            <Col span={8}></Col>
        </Row>
    );

};

export default Login;
