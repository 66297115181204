import React, { useState } from "react";
import { Input } from "antd";
import "./FloatLabel.css";

const FloatLabel = props => {
    const [focus, setFocus] = useState(false);
    const { children, label, required } = props;
    
    const isOccupied = focus || (children && children.props.value && children.props.value.length !== 0);

    const labelClass = isOccupied ? "custom-float-label as-label" : "custom-float-label as-placeholder";

    const requiredMark = required ? <span className="text-danger">*</span> : null;

    const isAntdInput = children && children.type && children.type === Input;

    const childrenWithClass = isAntdInput ? React.cloneElement(children, { className: "bigger-float-label" }) : children;

    return (
        <div
            className="float-label"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {childrenWithClass}
            <label className={labelClass}>
                {label} {requiredMark}
            </label>
        </div>
    );
};

export default FloatLabel;
