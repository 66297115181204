// src/EditableCell.js
import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, InputNumber } from 'antd';
import styled from 'styled-components';

// Create a styled Input component
const StyledInput = styled(Input)`
  background-color: #bdced9;
  width:50px;
  &:focus {
    background-color: #e0f2ff;
  }
`;

const StyledEditable = styled.div`
  background-color: #d1d4d5;
  text-align:center;
  width:75px;
  height:30px;
  &:focus {
    background-color: #e0f2ff;
  }
`;

const EditableCell = ({ record, field, id, update, rules = [], isModifiable, isNumeric, onEditStart, onEditFinish }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const [form] = Form.useForm();

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [field]: record[field] });
    };

    const handleSave = (value) => {
        update(field, id, value)
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave(values[field]);
            onEditFinish();
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    useEffect(() => {
        if (editing) {
            onEditStart();
            inputRef.current.focus();
        }
    }, [editing]);

    return editing ? (
        <Form form={form} component={false}>
            <Form.Item
                key={JSON.stringify(record)}
                style={{ margin: 0 }}
                name={field}
                rules={rules}
            >
                {
                    isNumeric ?
                        <InputNumber
                            min={-999}
                            max={999}
                            step={0.1}
                            precision={1}
                            key={JSON.stringify(record)} ref={inputRef} onPressEnter={save} onBlur={save}
                        />
                        :
                        <StyledInput key={JSON.stringify(record)} ref={inputRef} onPressEnter={save} onBlur={save} />
                }


            </Form.Item>
        </Form>
    ) : (
        isModifiable ?
            <StyledEditable key={JSON.stringify(record)} className="editable-cell-value-wrap" onClick={toggleEdit}>
                {record[field]}
            </StyledEditable>
            :
            <StyledEditable key={JSON.stringify(record)} className="editable-cell-value-wrap">
                {record[field]}
            </StyledEditable>
    );
};

export default EditableCell;
