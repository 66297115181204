import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Lift } from './components/Lift';
import { EquipmentIn } from './components/EquipmentIn';
import Monitoring from './components/Monitoring';
import { LiftPage } from './components/LiftPage';
import { Control } from './components/Control';
import { EquipmentQuery } from './components/EquipmentQuery';
import { EquipmentPhoto } from './components/EquipmentPhoto';

import Login from "./components/Login";
import { Logout } from "./components/Logout";
import { AuthorizeRoute } from './components/AuthorizeRoute';
import { Provider } from 'react-redux';
import store from './redux/store';
import './custom.css'
import 'antd/dist/antd.css';

import GlobalState from './context/globalState';

import Shift from "./components/Shift";
import Dashboard from "./components/Dashboard";
import Fuel from "./components/Fuel";
import PtiList from "./components/PtiList";
import Pti from "./components/Pti";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Provider store={store}>
                <GlobalState>
                    <Layout>
						<Switch>
							<Route exact path='/' component={Home} />
							<AuthorizeRoute path='/pti/dashboard' component={Dashboard} />
							<AuthorizeRoute path='/pti/fuel' component={Fuel} />							
							<AuthorizeRoute path='/pti/list' component={PtiList} /> 
							<AuthorizeRoute path='/pti/pti' component={Pti} />
							{/*							
							<AuthorizeRoute exact path='/dashboard/rpti' component={ReeferPTI} />
							*/}
							<AuthorizeRoute path='/shift' component={Shift} />
							<Route path='/login' component={Login} />
							<Route path='/Logout' component={Logout} />
							<AuthorizeRoute path='/lift' component={LiftPage} />
							<AuthorizeRoute path='/EquipmentIn' component={EquipmentIn} />
							<AuthorizeRoute path='/EquipmentQuery/:equip?' component={EquipmentQuery} />
							<AuthorizeRoute path='/EquipmentPhoto/:equip?' component={EquipmentPhoto} />
							<AuthorizeRoute path='/Control' component={Control} />
							<AuthorizeRoute path='/Monitoring' component={Monitoring} />
						</Switch>
                    </Layout>
                </GlobalState>

            </Provider>
        );
    }
}
