import * as type from '../types/globalStateTypes';
import AuthService from "../services/auth.service";


const initialState = {
    terminals: [],
    isLoadingTerminal:false,
    userData: {
        isAuthenticated: AuthService.isAuthenticated(),
        userName: AuthService.getCurrentUser() && AuthService.getCurrentUser() != null ? AuthService.getCurrentUser().userName: null
    }
};

const globalStateReducer = (state = initialState, action) => {

	switch (action.type) {
        case type.SET_TERMINALS:
         case  type.GET_USER:
            return action.payload;
       

		default: return state
	}
}

export default globalStateReducer;