import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import AuthService from "../services/auth.service";

export const AuthorizeRoute = (props) => {

    const render = () => {
        const { component: Component, ...rest } = props;
        return <Route {...rest}
            render={() => {
                const userAuthenticated = AuthService.isAuthenticated();
                console.log("AuthorizeRoute", userAuthenticated);
                if (userAuthenticated) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={'/login'} />
                }
            }} />
    }

    return (
        render()
    )
}

