import React, { useState, useEffect } from "react";
import { Select } from "antd";

const { Option } = Select;

const GenericDropdown = ({ onSelect, value, fetchDataFunction, labelProperty, keyProperty, isDisabled, ...params }) => {
    const [options, setOptions] = useState([]);

    const selectStyle = {
        width: '100%',
        fontSize: '20px'
    };

    useEffect(() => {
        const fetchData = async () => {
            if (params && params.length > 0) {
                fetchDataFunction(params, (response) => {
                    setOptions(response.data);
                });
            }
            else {
                fetchDataFunction((response) => {
                    setOptions(response.data);
                });
            }
        };

        fetchData();
    }, []);

    return (
        <Select showSearch value={value} style={selectStyle} disabled={isDisabled ? 'disabled' : null} size="large" onSelect={onSelect} >
            {
                options.map(item => (
                    <Option key={item[keyProperty]}>{item[labelProperty]}</Option>
                ))
            }
        </Select >
    );
};

export default GenericDropdown;
