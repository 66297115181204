import * as type from '../types/monitoringTypes';
import moment from "moment";

const initialState = {
    selecteddate: moment(),
    selectedOption: 'all'
}

const monitoringReducer = (state = initialState, action) => {
	switch (action.type) {
        case type.GET_DATA:
        case type.SAVE_DATA:
        case type.UPDATE_ROW_DATA:
        case type.CHANGE_DATE:
        case type.CHANGE_OPTION:
            return action.payload;

		default: return state
	}
}

export default monitoringReducer;