import axios from "axios";

//axios.interceptors.response.use(null, error => {
//    //console.log('Interceptor call');

//    const expectedError = error.response && error.status >= 400 && error.status < 500;

//    if (!expectedError) {
//        console.log("logging the error", error);
//        alert("An unexpected error occured");
//    }
//    return Promise.reject(error);
//});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete
};