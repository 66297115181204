import React, { useEffect } from "react";
import AuthService from "../services/auth.service";

export const Logout = (props) => {
    useEffect(() => {
        AuthService.logout();
        console.log("Logout useEffect");
        window.location.replace('/');
    }, [])


    const render = () => {
        return <div>Loging out...</div>
    }

    return (
        render()
    )
}
