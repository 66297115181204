import React, { useEffect } from "react";
import huntContext from './huntContext'
import { connect } from 'react-redux';
import * as actions from '../actions/globalStateAction';
import EventBus from '../components/common/EventBus';  
import AuthService from "../services/auth.service";


const GlobalState = (props) => {

    useEffect(() => {
        if (props.isUserAuthenticated)
            props.loadTerminals();

    }, [props.isUserAuthenticated]);

    useEffect(() => {
        EventBus.on("logout", (data) => {

            AuthService.logout();

            props.getUser({
                isAuthenticated: false,
                userName: undefined
            });
        });

        EventBus.on("login", (dada) => {
            const user = AuthService.getCurrentUser();
            props.getUser({
                isAuthenticated: user ? true : false,
                userName: user && user.userName,
                user:user
            });
        });

    }, []);


    return (
        <>
        <huntContext.Provider
            value={{ terminals:props.terminals }}
        >
            {
                props.isLoadingData == false ?
                 props.children : <div></div>
            }
            </huntContext.Provider>
            </>
    )
};

const mapStateToProps = state => {
    return {
        isLoadingData: state.globalStateReducer.isLoadingTerminal,
        isUserAuthenticated: state.globalStateReducer.userData.isAuthenticated,
        terminals: state.globalStateReducer.terminals
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTerminals: () => dispatch(actions.loadTerminals()),
        getUser: (data) => dispatch(actions.getUser(data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalState);
