import React, { useState, useEffect, useRef } from "react";
import {
    EquipmentIn
} from './EquipmentIn'
import {
    Lift
} from './Lift'


export const LiftPage = () => {
  const render = () => {
      return <div>
          <div style={{paddingBottom:'15px'} }>
              <Lift />
          </div>
          <div style={{ paddingTop: '5px' }}>
              <EquipmentIn />
            </div>
      </div>;
  };

  return render();
};
