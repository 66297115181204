import React, { useEffect } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import EventBus from "./common/EventBus";
import { connect } from 'react-redux';


const LoginMenu = (props) => {

    useEffect(() => {

        return () => {
            EventBus.remove("login");
            EventBus.remove("logout");
        };
    }, []); 


    const render = () => {
        return <>
            {
                props.isAuthenticated == true &&
                <>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to={'/'}>Hello {props.userName}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to={'/Logout'}>Logout</NavLink>
                    </NavItem>
                </>

            }
            {
                !props.isAuthenticated &&
                <>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to={'/Login'}>Login</NavLink>
                    </NavItem>
                </>
            }
        </>
    };
    return render();   
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.globalStateReducer.userData.isAuthenticated,
        userName: state.globalStateReducer.userData.userName
    };
} 
export default connect(
    mapStateToProps,
)(LoginMenu);