import axios from "axios";

const API_URL = "/api/Authentication/login/";

const login = (UserCode, Password) => {
    console.log('1. login:', UserCode, Password);
    return axios
        .post(API_URL, {
            UserCode,
            Password,
        })
        .then((response) => {
            console.log('2. login:', response.data);
            console.log('3. login:', JSON.stringify(response.data));
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    console.log("1. auth.services:logout");
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? true : false
};

export default {
    login,
    logout,
    getCurrentUser,
    isAuthenticated
};
