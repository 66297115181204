import { combineReducers } from 'redux';

const requireContext = require.context('../reducers', false, /.*\.(js)$/)

let reducers = {};

requireContext.keys().forEach(fileName => {
    const name = fileName.replace(/(\.\/|\.js)/g, '')
    reducers[name] = requireContext(fileName).default
})


const rootReducer = combineReducers(reducers)

export default rootReducer;