import React, { useState } from "react";
import { Table } from 'antd';
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";



const SortableTable = (props) => {

    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

   
    const getColumnWithSort = () => {
        return props.columns ? props.columns.map((col) => {

            if (col.isSort == true)
                return (
                    {
                        ...col,
                        sortOrder: sortedInfo.columnKey === col.dataIndex && sortedInfo.order,
                        sorter: (a, b) => a[col.dataIndex].localeCompare(b[col.dataIndex]),
                    });

            return { ...col };
        }) : [];
    };

    const getRowClassName = (record) => {
        return record.added ? 'monitoring-added-row' : '';
    };

    return (
        <>
            <Table
                columns={props.isSort == true ? getColumnWithSort() : props.columns}
                dataSource={props.data}
                onChange={handleChange}
                pagination={{ pageSize: 20 }}
                rowKey={props.rowKey}
                rowClassName={getRowClassName}
            />
        </>
    );
};


export default SortableTable;
