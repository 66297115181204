import store from '../redux/store';
import * as type from '../types/globalStateTypes';
import api from "../services/api";


export const loadTerminals =  () => {


    let state = store.getState().globalStateReducer;

    state = {
        ...state,
        isLoadingTerminal: true
    };

    api.apiAction({
        url: `terminals`,
        label: type.SET_TERMINALS,
        onSuccess: (data) => {
            console.log(data)
            state = {
                ...state,
                isLoadingTerminal: false,
                terminals: data
            };
            return {
                type: type.SET_TERMINALS,
                payload: state,

            }
        },
        onFailure: (error) => {
            state = {
                ...state,
                isLoadingTerminal: false
            };
            return {
                type: type.SET_TERMINALS,
                payload: state,
            }
        }
    });

    return {
        type: type.SET_TERMINALS,
        payload: state,
        
    }
}

export const getUser = (data) => {

    let state = store.getState().globalStateReducer;
    state = {
        ...state,
        userData: data,

    };

    return {
        type: type.GET_USER,
        payload: state,

    }
}
