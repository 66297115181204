import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../components/common/EventBus";

const API_URL = "/api/GeneralService/";

const getShippingLines = (callback) => {
    console.log('getShippingLines', authHeader())
    return axios.get(`${API_URL}ShippingLines`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getShippingLines logout");
                EventBus.dispatch("logout");
            }
        }
    );
};

const getIsoCodes = (callback) => {
    console.log('getIsoCodes', authHeader())
    return axios.get(`${API_URL}IsoCodes`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            console.log(error)
            if (error.response && error.response.status === 401) {
                console.log("SERVICE getIsoCodes logout");
                EventBus.dispatch("logout");
            }
        }
    );
};


export default {
    getShippingLines,
    getIsoCodes
};
