import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../components/common/EventBus";
import EventBusHandler from "../components/common/EventBusHandler";


const API_URL = "/api/monitoring/";

const findEquipment = (equipNum, terminal, callback) => {
    console.log('findEquipment', authHeader())
    return axios.get(`${API_URL}findEquipment/${equipNum}/${terminal}`, { headers: authHeader() }).then(
        (response) => {
            callback(response)
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("SERVICE findEquipment logout", error);
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 404) {
                console.log("444444000000004444444 ", error.response.status);
                callback(null)
            }
        }
    );
};

export default {
    findEquipment
};
